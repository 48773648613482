import React, { useState, useEffect } from 'react';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { useDispatch, useSelector } from 'react-redux';

import Auth from './Auth';
import Dashboard from './Dashboard';
import Loading from './shared/Loading';
import { setUser, updateUser } from '../store/actions';

function Main({}) {
  const dispatch = useDispatch();
  const user = useSelector(state => state.user);
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    const auth = getAuth();
    onAuthStateChanged(auth, (result) => {
      let user = {};

      if (result && !result.isAnonymous) {
        user = result;
      }

      if (user.uid) {
        dispatch(updateUser(user));
      } else {
        dispatch(setUser(user));
      }
      setInitialized(true);
    });
  }, []);

  if (user.uid) {
    return <Dashboard/>;
  }

  if (initialized) {
    return <Auth />;
  }

  return <Loading />;
}

export default Main;
