import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { getFirestore, collection, query, where, getDocs } from 'firebase/firestore';

import './Associate.scss';
import Header from '../../layout/Header';
import Home from './Home';
import Profile from './Profile';
import Course from './Course';
import Modal from '../../shared/Modal';
import { setAssociateCompletion, setCourses } from '../../../store/actions';

function Associate({}) {
  const dispatch = useDispatch();
  const user = useSelector(state => state.user);
  const coursesInitialized = useSelector(state => state.courses.initialized);
  const [modalTitle, setModalTitle] = useState('');
  const [modalText, setModalText] = useState('');

  useEffect(() => {
    if (!coursesInitialized && user.email) {
      fetchCourses(user.uid);
    }
  }, [coursesInitialized, user.email]);

  const fetchCourses = async (userId) => {
    try {
      const db = getFirestore();

      const q = query(collection(db, 'associate-training-courses'), where('enabled', '==', true));
      const coursesSnapshot = await getDocs(q);
      const completionQuery = query(collection(db, 'associate-training-completion'), where('userId', '==', userId));
      const completionSnapshot = await getDocs(completionQuery);

      const courses = coursesSnapshot.docs.map(doc => {
        return {
          ...doc.data(),
          id: doc.id,
        };
      });

      const courseCompletionMap = {};

      completionSnapshot.docs.forEach(doc => {
        const data = doc.data();

        courseCompletionMap[data.courseId] = { ...data, id: doc.id };
      });

      dispatch(setCourses(courses));
      dispatch(setAssociateCompletion({
        courses: courseCompletionMap,
      }));
    } catch (e) {
      console.log('error', e);
      setModalTitle('Error:');
      setModalText('There was an error retrieving your courses. If this problem persists, please contact us at hello@pupford.com.');
    }
  };

  return (
    <div className="Associate">
      <Header
        profileImageUrl={(user.profileImage && user.profileImage.url) || ''}
        userName={`${user.userName ? user.userName : ''}`}
        openNav={() => {}}
        hideNav={true}
      />

      <div className="content-container">
        <Switch>
          <Route path="/course/:path" component={Course} />
          <Route path="/account" component={Profile} />
          <Route component={Home} />
        </Switch>
      </div>

      <Modal
        open={!!modalText}
        close={() => {
          setModalTitle('');
          setModalText('');
        }}
        title={modalTitle}
        buttons={[
          <button key="modal-close" className="small" onClick={() => {
            setModalTitle('');
            setModalText('');
          }}>Close</button>,
        ]}
      >
        <div>
          <div className="modal-text">{modalText}</div>
        </div>
      </Modal>
    </div>
  );
}

export default Associate;
