import { getAuth } from 'firebase/auth';
import { useEffect, useState } from 'react';

import './Navigation.scss';

function Navigation({
  sections,
  currentSection,
  currentModule,
  changeSectionAndModule,
  moduleCompletion,
}) {
  const [openSections, setOpenSections] = useState([]);
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    checkIfAdmin();
  }, []);

  useEffect(() => {
    setOpenSections(sections.map((s, i) => i === currentSection));
  }, [sections, currentSection]);

  const checkIfAdmin = async () => {
    try {
      const auth = getAuth();
      const result = await auth.currentUser.getIdTokenResult();

      if (
        result &&
        result.claims &&
        result.claims.roles &&
        Array.isArray(result.claims.roles) &&
        result.claims.roles[0] &&
        (result.claims.roles[0].includes('partner-admin'))
      ) {
        setIsAdmin(true);
      }
    } catch (e) {
      console.log('error', e);
    }
  };

  const getSectionModulesCompleted = (modules) => {
    let completedCount = 0;

    modules.forEach(m => {
      if (moduleCompletion[m.id] && moduleCompletion[m.id].dateCompleted) {
        completedCount++;
      }
    });

    return completedCount;
  };

  return (
    <div className={`Navigation${isAdmin ? ' admin' : ''}`}>
      <div className="navigation-inner-container">
        <div className="navigation-header-container">
          <h3>Course Content</h3>
        </div>

        <div className="navigation-sections-container">
          {sections.map((section, i) => {
            return (
              <div className="section-container" key={`section-${i}`}>
                <button
                  className="section-title-container"
                  onClick={() => {
                    const openSectionsCopy = [ ...openSections ];
                    openSectionsCopy[i] = !openSectionsCopy[i];
                    setOpenSections(openSectionsCopy);
                  }}
                >
                  <div className="section-title-details">
                    <p className="section-title">{section.title}</p>
                    <p className="section-completion">{getSectionModulesCompleted(section.modules)} / {section.modules.length} Completed</p>
                  </div>

                  <i className={`fas fa-chevron-down${openSections[i] ? ' open' : ''}`}></i>
                </button>

                {!openSections[i] ? null :
                  <>
                    {section.modules.map((module, j) => {
                      return (
                        <button
                          className={`module-container${(i === currentSection && j === currentModule) ? ' active' : ''}`}
                          key={`module-${i}-${j}`}
                          onClick={() => changeSectionAndModule(i, j)}
                        >
                          <div className="module-complete-container">
                            <div>
                              {moduleCompletion[module.id] && moduleCompletion[module.id].dateCompleted ?
                                <i className="fa fa-check-circle-o"/> :
                                <i className="far fa-circle"/>
                              }
                            </div>
                          </div>

                          <div className="module-text-container">
                            <p className="module-title">{module.title}</p>
                            <p className="module-type">{module.type}</p>
                          </div>
                        </button>
                      );
                    })}
                  </>
                }
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default Navigation;
