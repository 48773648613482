import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import './Home.scss';
import Loading from '../../../shared/Loading';

function Home({}) {
  const courses = useSelector(state => state.courses.courses);
  const associateCompletion = useSelector(state => state.associateCompletion);
  const [courseStatuses, setCourseStatuses] = useState({});

  useEffect(() => {
    if (associateCompletion.initialized && courses.length) {
      initializeCourseStatuses();
    }
  }, [associateCompletion.initialized, courses]);

  const initializeCourseStatuses = () => {
    const updatedCourseStatuses = {};

    courses.forEach(course => {
      if (associateCompletion.courses[course.id] && associateCompletion.courses[course.id].modules) {
        const completedModules = associateCompletion.courses[course.id].modules;
        let isComplete = true;

        course.sections.forEach(section => {
          section.modules.forEach(module => {
            if (module.type === 'quiz') {
              const id = module.id;

              if (!completedModules[id] || !completedModules[id].dateCompleted) {
                isComplete = false;
              }
            }
          });
        });

        updatedCourseStatuses[course.id] = isComplete ? 'complete' : 'in-progress';
      } else {
        updatedCourseStatuses[course.id] = 'not-started';
      }
    });

    setCourseStatuses(updatedCourseStatuses);
  };

  const renderStatus = (status) => {
    return <span className={status}>{status.split('-').join(' ')}</span>;
  };

  return (
    <div className="Home">
      <div className="home-header">
        <div className="associate-max-width">
          <h1>My learning</h1>
        </div>
      </div>

      <div className="home-content">
        <div className="associate-max-width">
          {!courses.length ?
            <div className="home-loading-container">
              <Loading position="absolute"/>
            </div> :
            <div>
              {courses.map(c => {
                return (
                  <div className="course-outer-container" key={c.id}>
                    <Link to={`/course/${c.webPath}`}>
                      <div className="course-container">
                        <div className="course-image-container">
                          <img src={c.backgroundImage} alt={c.name}/>
                        </div>
                        <div className="course-details-container">
                          <p className="course-title">{c.name}</p>
                          <p className="course-description">{c.description}</p>
                          <p className="course-status">
                            Status: {renderStatus(courseStatuses[c.id] || 'not-started')}
                          </p>
                        </div>
                      </div>
                    </Link>
                  </div>
                );
              })}
            </div>
          }
        </div>
      </div>
    </div>
  );
}

export default Home;
