import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useHistory, Link } from 'react-router-dom';
import { getFirestore, collection, addDoc, doc, updateDoc } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';

import './Course.scss';
import Loading from '../../../shared/Loading';
import MainContent from './MainContent';
import AdditionalContent from './AdditionalContent';
import Navigation from './Navigation';
import Modal from '../../../shared/Modal';
import { addCourseToAssociateCompletion, updateAssociateCompletionCourse } from '../../../../store/actions';

function Course({}) {
  const dispatch = useDispatch();
  const params = useParams();
  const history = useHistory();
  const courses = useSelector(state => state.courses.courses);
  const user = useSelector(state => state.user);
  const associateCompletion = useSelector(state => state.associateCompletion);
  const [modalTitle, setModalTitle] = useState('');
  const [modalText, setModalText] = useState('');
  const [course, setCourse] = useState({
    sections: [{
      modules: [{}],
    }]
  });
  const [sectionAndModuleIndex, setSectionAndModuleIndex] = useState({
    section: 0,
    module: 0,
  });
  const [currentCourseCompletion, setCurrentCourseCompletion] = useState();

  useEffect(() => {
    window.scrollTo(0, 0);

    if (courses.length && params.path) {
      const foundCourseIndex = courses.findIndex(c => c.webPath === params.path);

      if (foundCourseIndex === -1) {
        history.replace('/');
        return;
      }

      setCourse(courses[foundCourseIndex]);
    }
  }, [courses, params]);

  useEffect(() => {
    if (course.id && associateCompletion.initialized && user.email) {
      initializeCurrentCourseCompletion(course.id, user.uid);
    }
  }, [associateCompletion.initialized, user.email, course.id]);

  useEffect(() => {
    if (course.id && associateCompletion.initialized && user.email) {
      checkAndUpdateCourseCompletion();
    }
  }, [sectionAndModuleIndex.section, sectionAndModuleIndex.module, course.id && associateCompletion.initialized && user.email]);

  const initializeCurrentCourseCompletion = async (courseId, userId) => {
    if (associateCompletion.courses[courseId]) {
      setCurrentCourseCompletion(associateCompletion.courses[courseId]);
    } else {
      try {
        const auth = getAuth();
        const result = await auth.currentUser.getIdTokenResult();
        let partnerId;

        if (result && result.claims && result.claims.partner) {
          partnerId = result.claims.partner;
        }

        const db = getFirestore();
        const dataToAdd = {
          userId,
          partnerId,
          courseId,
          modules: {},
          created: Date.now(),
        };

        if (
          course.sections &&
          course.sections[sectionAndModuleIndex.section] &&
          course.sections[sectionAndModuleIndex.section].modules &&
          course.sections[sectionAndModuleIndex.section].modules[sectionAndModuleIndex.module] &&
          course.sections[sectionAndModuleIndex.section].modules[sectionAndModuleIndex.module].id &&
          course.sections[sectionAndModuleIndex.section].modules[sectionAndModuleIndex.module].type !== 'quiz'
        ) {
          const currentModuleId = `${course.sections[sectionAndModuleIndex.section].modules[sectionAndModuleIndex.module].id}`;
          dataToAdd.modules[currentModuleId] = {
            type: course.sections[sectionAndModuleIndex.section].modules[sectionAndModuleIndex.module].type,
            dateCompleted: Date.now(),
          };
        }

        const docRef = await addDoc(collection(db, 'associate-training-completion'), dataToAdd);

        const courseCompletion = {
          ...dataToAdd,
          id: docRef.id,
        };
        dispatch(addCourseToAssociateCompletion(courseCompletion));
        setCurrentCourseCompletion(courseCompletion);
      } catch (e) {
        console.log('error', e);
        setModalTitle('Error:');
        setModalText('There was an error retrieving your course status. If this problem persists, please contact us at hello@pupford.com.');
      }
    }
  };

  const checkAndUpdateCourseCompletion = async () => {
    try {
      if (
        currentCourseCompletion &&
        currentCourseCompletion.id &&
        course.sections &&
        course.sections[sectionAndModuleIndex.section] &&
        course.sections[sectionAndModuleIndex.section].modules &&
        course.sections[sectionAndModuleIndex.section].modules[sectionAndModuleIndex.module] &&
        course.sections[sectionAndModuleIndex.section].modules[sectionAndModuleIndex.module].id &&
        course.sections[sectionAndModuleIndex.section].modules[sectionAndModuleIndex.module].type !== 'quiz'
      ) {
        const moduleId = course.sections[sectionAndModuleIndex.section].modules[sectionAndModuleIndex.module].id;
        const completedModule = currentCourseCompletion.modules[moduleId] || {};

        if (!completedModule.dateCompleted) {
          const db = getFirestore();
          const updatedCompletedModules = { ...currentCourseCompletion.modules };
          updatedCompletedModules[moduleId] = {
            type: course.sections[sectionAndModuleIndex.section].modules[sectionAndModuleIndex.module].type,
            dateCompleted: Date.now(),
          };

          const courseCompletionRef = doc(db, 'associate-training-completion', currentCourseCompletion.id);

          await updateDoc(courseCompletionRef, {
            modules: updatedCompletedModules,
          });

          dispatch(updateAssociateCompletionCourse({
            ...currentCourseCompletion,
            modules: updatedCompletedModules,
          }));
          setCurrentCourseCompletion({
            ...currentCourseCompletion,
            modules: updatedCompletedModules,
          });
        }
      }
    } catch (e) {
      console.log('error', e);
    }
  };

  const updateQuizModule = async (moduleId, data) => {
    try {
      const db = getFirestore();
      const updatedCompletedModules = { ...currentCourseCompletion.modules };
      updatedCompletedModules[moduleId] = data;

      const courseCompletionRef = doc(db, 'associate-training-completion', currentCourseCompletion.id);

      await updateDoc(courseCompletionRef, {
        modules: updatedCompletedModules,
      });

      dispatch(updateAssociateCompletionCourse({
        ...currentCourseCompletion,
        modules: updatedCompletedModules,
      }));
      setCurrentCourseCompletion({
        ...currentCourseCompletion,
        modules: updatedCompletedModules,
      });
    } catch (e) {
      console.log('error', e);
    }
  };

  return (
    <div className="Course">
      {(!courses.length || !currentCourseCompletion) && !modalTitle ? <Loading/> :
        <div className="course-outer-container">
          <div className="course-content-container">
            <MainContent
              module={course.sections[sectionAndModuleIndex.section].modules[sectionAndModuleIndex.module]}
              moduleCompletion={currentCourseCompletion.modules}
              updateQuizModule={updateQuizModule}
            />
            <AdditionalContent
              module={course.sections[sectionAndModuleIndex.section].modules[sectionAndModuleIndex.module]}
            />
          </div>

          <div className="course-navigation-container">
            <Navigation
              sections={course.sections}
              currentSection={sectionAndModuleIndex.section}
              currentModule={sectionAndModuleIndex.module}
              changeSectionAndModule={(section, module) => {
                setSectionAndModuleIndex({
                  section,
                  module,
                });
              }}
              moduleCompletion={currentCourseCompletion.modules}
            />
          </div>
        </div>
      }

      <Modal
        open={!!modalText}
        close={() => {
          setModalTitle('');
          setModalText('');
        }}
        title={modalTitle}
        buttons={[
          <button key="modal-close" className="small" onClick={() => {
            setModalTitle('');
            setModalText('');
          }}>Close</button>,
        ]}
      >
        <div>
          <div className="modal-text">{modalText}</div>
        </div>
      </Modal>
    </div>
  );
}

export default Course;
