import { useEffect, useState } from 'react';
import axios from 'axios';

import './VideoContent.scss';

function VideoContent({ videoUrl, videoThumbnail }) {
  const [loading, setLoading] = useState(false);
  const [vimeoEmbed, setVimeoEmbed] = useState('');

  useEffect(() => {
    if (videoUrl) {
      getVimeoEmbed(videoUrl);
    }
  }, [videoUrl]);

  const getVimeoEmbed = async (videoUrl) => {
    try {
      const videoUrlArr = videoUrl.split('/');
      let videoId;

      if (videoUrlArr[5] && !isNaN(parseInt(videoUrlArr[5]))) {
        videoId = videoUrlArr[5];
      } else {
        setVimeoEmbed('');
        return;
      }

      const result = await axios.get(`https://vimeo.com/api/oembed.json?url=https://vimeo.com/${videoId}`);

      if (!result || !result.data || !result.data.html) {
        setVimeoEmbed('');
        return;
      }

      const iframeDiv = document.createElement('div');
      iframeDiv.innerHTML = result.data.html;

      const iframe = iframeDiv.firstChild;
      iframe.height = '100%';
      iframe.width = '100%';

      setVimeoEmbed(iframe.outerHTML);
    } catch (e) {
      console.log('error', e);
      setVimeoEmbed('');
    }
  };

  if (vimeoEmbed) {
    return (
      <div className="VideoContent">
        <div className="video-container">
          <div className="video-inner" dangerouslySetInnerHTML={{__html: vimeoEmbed}}>
          </div>
        </div>
      </div>
    );
  }

  return (
    <video width="100%" height="100%" controls poster={videoThumbnail}>
      <source src={videoUrl} type="video/mp4"/>
      Your browser does not support the video tag.
    </video>
  );
}

export default VideoContent;
