import {
  SET_TEAM,
} from '../actions/types';

const defaultState = {
  initialized: false,
  members: [],
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case SET_TEAM:
      return {
        ...state,
        initialized: true,
        members: action.payload,
      };
    default:
      return state;
  }
};
