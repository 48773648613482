import draftToHtml from 'draftjs-to-html';

import './ArticleContent.scss';
import DangerouslySetHtmlContent from '../../../../../shared/DangerouslySetHtmlContent';

function ArticleContent({ editorData }) {
  return (
    <div className="ArticleContent">
      <DangerouslySetHtmlContent html={draftToHtml(JSON.parse(editorData))} />
    </div>
  );
}

export default ArticleContent;
