import './MainContent.scss';
import VideoContent from './VideoContent';
import QuizContent from './QuizContent';
import ArticleContent from './ArticleContent';

function MainContent({ module, moduleCompletion, updateQuizModule }) {

  const renderContentTypes = () => {
    switch (module.type) {
      case 'video':
        return (
          <VideoContent
            videoUrl={module.videoUrl}
            videoThumbnail={module.videoThumbnail}
          />
        );
      case 'quiz':
        return (
          <QuizContent
            questions={module.questions}
            passingScore={module.passingScore ? +module.passingScore : 0}
            moduleCompletion={moduleCompletion}
            moduleId={module.id}
            onUpdate={updateQuizModule}
          />
        );
      case 'article':
        return <ArticleContent editorData={module.editorData}/>;
      default:
        return null;
    }
  };

  return (
    <div className="MainContent">
      <div className="main-content-inner">
        {renderContentTypes()}
      </div>
    </div>
  );
}

export default MainContent;
