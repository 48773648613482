import { useEffect, useState } from 'react';
import { getAuth } from 'firebase/auth';
import { useDispatch, useSelector } from 'react-redux';
import { getFirestore, collection, query, where, getDocs } from 'firebase/firestore';
import axios from 'axios';

import './Team.scss';
import Modal from '../../../shared/Modal';
import Loading from '../../../shared/Loading';
import { setTeam } from '../../../../store/actions';
import { config } from '../../../../config';

function Team({}) {
  const dispatch = useDispatch();
  const teamMembers = useSelector(state => state.team.members);
  const teamInitialized = useSelector(state => state.team.initialized);
  const courses = useSelector(state => state.courses.courses);
  const [loading, setLoading] = useState(true);
  const [modalTitle, setModalTitle] = useState('');
  const [modalText, setModalText] = useState('');
  const [addTeamMemberOpen, setAddTeamMemberOpen] = useState(false);
  const [addTeamMember, setAddTeamMember] = useState({
    userName: '',
    email: '',
  });
  const [addingTeamMember, setAddingTeamMember] = useState(false);
  const [removeIndex, setRemoveIndex] = useState(-1);
  const [removingUser, setRemovingUser] = useState(false);

  useEffect(() => {
    if (!teamInitialized) {
      fetchTeam();
    } else {
      setLoading(false);
    }
  }, [teamInitialized]);

  const fetchTeam = async () => {
    try {
      const auth = getAuth();
      const result = await auth.currentUser.getIdTokenResult();
      let partnerId;

      if (result && result.claims && result.claims.partner) {
        partnerId = result.claims.partner;
      } else {
        setLoading(false);
        setModalTitle('Notice:');
        setModalText('You do not have access to this resource. If you need assistance, please contact us at hello@pupford.com.');
        return;
      }

      const db = getFirestore();
      const q = query(collection(db, 'users'), where('partner', '==', partnerId));
      const usersSnapshot = await getDocs(q);

      const users = usersSnapshot.docs.map(doc => {
        return {
          id: doc.id,
          ...doc.data(),
          courseCompletion: {},
        };
      }).filter(user => user.id !== auth.currentUser.uid);

      const completionQuery = query(collection(db, 'associate-training-completion'), where('partnerId', '==', partnerId));
      const courseCompletionDocs = await getDocs(completionQuery);

      courseCompletionDocs.forEach(doc => {
        const data = doc.data();
        const userId = data.userId;

        const foundUserIndex = users.findIndex(u => u.id === userId);

        if (foundUserIndex !== -1) {
          users[foundUserIndex].courseCompletion[data.courseId] = data;
        }
      });

      dispatch(setTeam(users));
      setLoading(false);
    } catch (e) {
      console.log('error', e);
      setLoading(false);
      setModalTitle('Error:');
      setModalText('There was an error retrieving the users in your organization. If this issue persists, please contact us for assistance.');
    }
  };

  const submitAddTeamMember = async () => {
    setAddTeamMemberOpen(false);
    setAddingTeamMember(true);

    try {
      const auth = getAuth();
      const tokenResult = await auth.currentUser.getIdTokenResult();
      let partnerId;

      if (tokenResult && tokenResult.claims && tokenResult.claims.partner) {
        partnerId = tokenResult.claims.partner;
      } else {
        setAddingTeamMember(false);
        setModalTitle('Notice:');
        setModalText('You do not have permission to perform this action. If you need assistance, please contact us at hello@pupford.com.');
        return;
      }

      const email = addTeamMember.email.trim().toLowerCase();
      const userData = {
        email,
        roles: [ 'partner-associate' ],
        partner: partnerId,
        userName: addTeamMember.userName,
      };

      const token = await auth.currentUser.getIdToken();
      const result = await axios(`${config.gateway}/user-service/api/v1/partner-admin/associate`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
        },
        data: userData,
      });

      setAddingTeamMember(false);

      if (result && result.data && result.data.userId) {
        dispatch(setTeam([
          ...teamMembers,
          {
            ...userData,
            id: result.data.userId,
            courseCompletion: {},
          },
        ]));
      } else {
        setModalTitle('Error:');
        setModalText('There was an error adding this user to your organization. If this issue persists, please contact us for assistance.');
      }
    } catch (e) {
      setAddingTeamMember(false);
      setModalTitle('Error:');
      setModalText('There was an error adding this user to your organization. If this issue persists, please contact us for assistance.');
    }
  };

  const confirmRemoveTeamMember = async () => {
    const teamMemberIndex = removeIndex;
    
    setRemoveIndex(-1);
    setRemovingUser(true);

    try {
      const auth = getAuth();
      const tokenResult = await auth.currentUser.getIdTokenResult();
      let partnerId;

      if (tokenResult && tokenResult.claims && tokenResult.claims.partner) {
        partnerId = tokenResult.claims.partner;
      } else {
        setAddingTeamMember(false);
        setModalTitle('Notice:');
        setModalText('You do not have permission to perform this action. If you need assistance, please contact us at hello@pupford.com.');
        return;
      }

      const token = await auth.currentUser.getIdToken();
      const result = await axios(`${config.gateway}/user-service/api/v1/partner-admin/partner/${partnerId}/associate/${teamMembers[teamMemberIndex].id}/remove-access`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
        },
        data: {},
      });

      setRemovingUser(false);

      if (result && result.data && result.data.status && result.data.status === 'success') {
        const updatedTeam = [ ...teamMembers ];
        updatedTeam.splice(teamMemberIndex, 1);
        dispatch(setTeam(updatedTeam));
      } else {
        setModalTitle('Error:');
        setModalText('There was an error removing this user from your organization. If this issue persists, please contact us for assistance.');
      }
    } catch (e) {
      setRemovingUser(false);
      setModalTitle('Error:');
      setModalText('There was an error removing this user from your organization. If this issue persists, please contact us for assistance.');
    }
  };

  const getCompletedCourseCount = (courseCompletion) => {
    if (!courses.length) {
      return 'Loading...';
    }

    let numberOfCompletedCourses = 0;

    courses.forEach(course => {
      if (courseCompletion[course.id] && courseCompletion[course.id].modules) {
        const completedModules = courseCompletion[course.id].modules;
        let isComplete = true;

        course.sections.forEach(section => {
          section.modules.forEach(module => {
            if (module.type === 'quiz') {
              const id = module.id;

              if (!completedModules[id] || !completedModules[id].dateCompleted) {
                isComplete = false;
              }
            }
          });
        });

        if (isComplete) {
          numberOfCompletedCourses++;
        }
      }
    });

    return `${numberOfCompletedCourses} / ${courses.length}`;
  };

  return (
    <div className="Team">
      {loading ? <Loading/> :
        <div className="team-content-container">
          {!removingUser ? null : <Loading position="absolute" />}
          <div className="header-container">
            <h1>Team</h1>

            <button
              className="add-team-member-button"
              onClick={() => {
                if (addingTeamMember) {
                  return;
                }

                setAddTeamMember({
                  userName: '',
                  email: '',
                });
                setAddTeamMemberOpen(true);
              }}
            >
              {addingTeamMember ?
                <i className="fas fa-spinner fa-pulse"></i> :
                'Add Team Member'
              }
            </button>
          </div>

          <div className="table-container">
            <table>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Roles</th>
                  <th>Completed Courses</th>
                  <th></th>
                </tr>
              </thead>

              <tbody>
                {teamMembers.map((u, i) => {
                  return (
                    <tr key={u.id}>
                      <td>{u.userName || 'None specified'}</td>
                      <td>{u.email || 'None specified'}</td>
                      <td>
                        <span className="capitalize">
                          {((u.roles || []).map(role => role.split('-').join(' ').replace('partner', '')).join(', '))}
                        </span>
                      </td>
                      <td>
                        {u.roles && u.roles[0] !== 'partner-associate' ? 'NA' : getCompletedCourseCount(u.courseCompletion)}
                      </td>

                      <td className="button-container">
                        <button onClick={() => setRemoveIndex(i)}>
                          Remove
                        </button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      }

      <Modal
        open={addTeamMemberOpen}
        close={() => {
          setAddTeamMemberOpen(false);
        }}
        title="Add Team Member"
        buttons={[
          <button
            key="modal-submit"
            className="small"
            disabled={!addTeamMember.userName || !addTeamMember.email}
            onClick={submitAddTeamMember}
          >Submit</button>,
          <button
            key="modal-close"
            className="small secondary"
            onClick={() => setAddTeamMemberOpen(false)}
          >Cancel</button>,
        ]}
      >
        <div>
          <div className="input-container">
            <label>Name:</label>
            <input
              type="text"
              value={addTeamMember.userName}
              placeholder="Enter name"
              onChange={(e) => {
                setAddTeamMember({
                  ...addTeamMember,
                  userName: e.target.value,
                });
              }}
            />
          </div>

          <div className="input-container">
            <label>Email:</label>
            <input
              type="email"
              value={addTeamMember.email}
              placeholder="joe@example.com"
              onChange={(e) => {
                setAddTeamMember({
                  ...addTeamMember,
                  email: e.target.value,
                });
              }}
            />
          </div>
        </div>
      </Modal>

      <Modal
        open={removeIndex !== -1}
        close={() => setRemoveIndex(-1)}
        title="Remove Team Member?"
        buttons={[
          <button
            key="modal-confirm"
            className="small secondary"
            onClick={confirmRemoveTeamMember}
          >Confirm</button>,
          <button
            key="modal-close"
            className="small"
            onClick={() => setRemoveIndex(-1)}
          >Cancel</button>,
        ]}
      >
        <div>
          <div className="modal-text">
            Are you sure you want to remove <strong>{removeIndex === -1 ? '' : (teamMembers[removeIndex].userName || teamMembers[removeIndex].email)}</strong>?
          </div>
        </div>
      </Modal>

      <Modal
        open={!!modalText}
        close={() => {
          setModalTitle('');
          setModalText('');
        }}
        title={modalTitle}
        buttons={[
          <button key="modal-close" className="small" onClick={() => {
            setModalTitle('');
            setModalText('');
          }}>Close</button>,
        ]}
      >
        <div>
          <div className="modal-text">{modalText}</div>
        </div>
      </Modal>
    </div>
  );
}

export default Team;
