import { useEffect, useState } from 'react';

import './QuizSummary.scss';

function QuizSummary({
  moduleCompletion,
  questions,
}) {
  const [correctAnswers, setCorrectAnswers] = useState([]);
  const [incorrectAnswers, setIncorrectAnswers] = useState([]);

  useEffect(() => {
    if (
      moduleCompletion.answers &&
      moduleCompletion.answers.length &&
      questions.length &&
      moduleCompletion.answers.length === questions.length
    ) {
      const updatedCorrectAnswers = [];
      const updatedIncorrectAnswers = [];

      questions.forEach((question, i) => {
        const answer = moduleCompletion.answers.find(a => +a.id === +question.id);
        
        if (answer) {
          if (answer.correct) {
            updatedCorrectAnswers.push(question.question);
          } else {
            updatedIncorrectAnswers.push({
              question: question.question,
              answer: question.answers.find(a => a.correct).answer,
            });
          }
        }
      });

      setCorrectAnswers(updatedCorrectAnswers);
      setIncorrectAnswers(updatedIncorrectAnswers);
    }
  }, [moduleCompletion, questions]);

  return (
    <div className="QuizSummary">
      <div className="summary-container">
        <p><strong>What you know</strong></p>

        {correctAnswers.map((answer, i) => {
          return (
            <p key={`correct-answer-${i}`}><i className="fa fa-check-circle-o"/> {answer}</p>
          );
        })}
      </div>

      {!incorrectAnswers.length ? null :
        <div className="summary-container">
          <p><strong>What you should review</strong></p>

          {incorrectAnswers.map((answer, i) => {
            return (
              <div key={`incorrect-answer-${i}`}>
                <p><i className="fa fa-times-circle-o"/> {answer.question}</p>
                <p className="correct-answer"><strong>Correct Answer:</strong> {answer.answer}</p>
              </div>
            );
          })}
        </div>
      }
    </div>
  );
}

export default QuizSummary;