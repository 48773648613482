import {
  SET_ASSOCIATE_COMPLETION,
  ADD_COURSE_TO_ASSOCIATE_COMPLETION,
  UPDATE_ASSOCIATE_COMPLETION_COURSE,
} from './types';

export const setAssociateCompletion = (courses) => {
  return {
    type: SET_ASSOCIATE_COMPLETION,
    payload: courses,
  };
};

export const addCourseToAssociateCompletion = (course) => {
  return {
    type: ADD_COURSE_TO_ASSOCIATE_COMPLETION,
    payload: course,
  };
};

export const updateAssociateCompletionCourse = (course) => {
  return {
    type: UPDATE_ASSOCIATE_COMPLETION_COURSE,
    payload: course,
  };
};
