import { useEffect, useState } from 'react';

import './QuizQuestion.scss';

function QuizQuestion({ question, questionNumber, numberOfQuestions, onSubmit }) {
  const [loading, setLoading] = useState(false);
  const [selectedMultipleChoiceAnswer, setSelectedMultipleChoiceAnswer] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    setLoading(false);
    setSelectedMultipleChoiceAnswer(null);
  }, [question]);

  const onSubmitMultipleChoiceAnswer = (e) => {
    e.preventDefault();

    if (loading) {
      return;
    }

    if (typeof selectedMultipleChoiceAnswer !== 'number') {
      setErrorMessage('Please select an answer.');
      return;
    }

    setLoading(true);
    onSubmit(!!question.answers[selectedMultipleChoiceAnswer].correct, selectedMultipleChoiceAnswer);
  };

  if (question.type === 'multiple-choice') {
    return (
      <form
        className="QuizQuestion"
        onSubmit={onSubmitMultipleChoiceAnswer}
      >
        <p className="question-number">Question {questionNumber} of {numberOfQuestions}</p>
        <p className="question">{question.question}</p>
        <div>
          {question.answers.map((a, i) => {
            return (
              <span key={`answer-${i}`} className="multiple-choice-answer">
                <input
                  type="radio"
                  value={i}
                  name="question"
                  checked={i === selectedMultipleChoiceAnswer}
                  onChange={(e) => {
                    setSelectedMultipleChoiceAnswer(+e.target.value);

                    if (errorMessage) {
                      setErrorMessage('');
                    }
                  }}
                /> {a.answer}
              </span>
            );
          })}
        </div>
        {!errorMessage ? null : <p className="error-message">{errorMessage}</p>}
        <button>{loading ? <i className="fas fa-spinner fa-pulse"/> : 'Submit'}</button>
      </form>
    );
  } else {
    return <p>Something went wrong. Please contact us for assistance.</p>;
  }
}

export default QuizQuestion;
