import { useState } from 'react';

import './AdditionalContent.scss';

function AdditionalContent({ module }) {
  const [currentTab, setCurrentTab] = useState('notes');

  return (
    <div className="AdditionalContent">
      <div className="tabs-container">
        <div
          className={`tab-container${currentTab === 'notes' ? ' active' : ''}`}
          onClick={() => setCurrentTab('notes')}
        >
          Notes
        </div>
        <div
          className={`tab-container${currentTab === 'links' ? ' active' : ''}`}
          onClick={() => setCurrentTab('links')}
        >
          Links
        </div>
      </div>

      {currentTab === 'notes' ?
        <div className="notes-container">
          {module.notes || 'There are no notes for this module.'}
        </div> :
        <div className="links-container">
          {!module.links.length ? 'There are no links for this module.' :
            <>
              {module.links.map((link, i) => {
                return (
                  <div key={`link-${i}`}>
                    <p>
                      <strong>{link.title}</strong> <a href={link.value}>{link.value}</a>
                    </p>
                  </div>
                );
              })}
            </>
          }
        </div>
      }
    </div>
  );
}

export default AdditionalContent;
