import React, { useEffect, useRef } from 'react';

function DangerouslySetHtmlContent(props) {
  const { html, ...rest } = props;
  const divRef = useRef(null);

  useEffect(() => {
    if (!html) {
      return;
    }

    const slotHtml = document.createRange().createContextualFragment(html);
    divRef.current.innerHTML = '';
    divRef.current.appendChild(slotHtml);
  }, [html]);

  return (
    <div {...rest} ref={divRef}></div>
  );
}

export default DangerouslySetHtmlContent;
