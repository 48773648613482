import './QuizContent.scss';
import QuizQuestion from './QuizQuestion';
import QuizSummary from './QuizSummary';

function QuizContent({
  questions,
  passingScore,
  moduleCompletion,
  moduleId,
  onUpdate,
}) {

  const getNumberOfCorrectMessage = () => {
    if (moduleCompletion && moduleCompletion[moduleId] && moduleCompletion[moduleId].answers) {
      let numberOfCorrect = 0;

      moduleCompletion[moduleId].answers.forEach(answer => {
        if (answer.correct) {
          numberOfCorrect++;
        }
      });

      return `You got ${numberOfCorrect} out of ${questions.length} correct and are ready to move on.`
    } else {
      return 'You have completed this quiz!';
    }
  };

  const getRetakeQuizMessage = () => {
    if (moduleCompletion && moduleCompletion[moduleId] && moduleCompletion[moduleId].answers) {
      let numberOfCorrect = 0;

      moduleCompletion[moduleId].answers.forEach(answer => {
        if (answer.correct) {
          numberOfCorrect++;
        }
      });

      return `You got ${numberOfCorrect} out of ${questions.length} correct. Get at least ${passingScore} out of ${questions.length} correct to complete this module.`;
    }

    return null;
  };

  return (
    <div className="QuizContent">
      {(moduleId && moduleCompletion && moduleCompletion[moduleId] && moduleCompletion[moduleId].dateCompleted) ?
        <div className="completed-message-container">
          <div className="completed-message">
            <div className="completed-message-content">
              <h1>Congratulations!</h1>
              <p>{getNumberOfCorrectMessage()}</p>

              <QuizSummary
                moduleCompletion={moduleCompletion[moduleId]}
                questions={questions}
              />
            </div>
          </div>
        </div> :
        <>
          {moduleId && moduleCompletion && moduleCompletion[moduleId] && moduleCompletion[moduleId].currentQuestion ?
            <div className="current-question-container">
              <QuizQuestion
                question={questions[moduleCompletion[moduleId].currentQuestion - 1]}
                questionNumber={moduleCompletion[moduleId].currentQuestion}
                numberOfQuestions={questions.length}
                onSubmit={(correct, selectedAnswer) => {
                  const question = questions[moduleCompletion[moduleId].currentQuestion - 1];
                  const updatedModuleCompletion = {
                    ...moduleCompletion[moduleId],
                    currentQuestion: moduleCompletion[moduleId].currentQuestion + 1,
                    answers: [
                      ...moduleCompletion[moduleId].answers,
                      {
                        id: question.id,
                        correct,
                        selectedAnswer,
                        submitted: Date.now(),
                      },
                    ],
                  };

                  const isLastQuestion = !questions[moduleCompletion[moduleId].currentQuestion];

                  if (isLastQuestion) {
                    updatedModuleCompletion.lastSubmitDate = Date.now();
                    updatedModuleCompletion.submitCount = updatedModuleCompletion.submitCount + 1;
                    updatedModuleCompletion.currentQuestion = 0;

                    let score = 0;

                    updatedModuleCompletion.answers.forEach(a => {
                      if (a.correct) {
                        score++;
                      }
                    });

                    if (score >= passingScore) {
                      updatedModuleCompletion.dateCompleted = updatedModuleCompletion.lastSubmitDate;
                    }
                  }

                  onUpdate(moduleId, updatedModuleCompletion);
                }}
              />
            </div> :
            <>
              {moduleId && moduleCompletion && moduleCompletion[moduleId] && moduleCompletion[moduleId].submitCount ?
                <div className="start-quiz-container">
                  <div className="start-quiz">
                    <h1>Not quite there yet.</h1>
                    <p>{getRetakeQuizMessage()}</p>
                    <button
                      onClick={() => {
                        const updatedModuleCompletion = {
                          ...moduleCompletion[moduleId],
                          currentQuestion: 1,
                          answers: [],
                        };

                        onUpdate(moduleId, updatedModuleCompletion);
                      }}
                    >
                      Retake Quiz
                    </button>
                  </div>
                </div> :
                <div className="start-quiz-container">
                  <div className="start-quiz">
                    <h1>Take this short quiz to see what you've learned.</h1>
                    <button
                      onClick={() => {
                        const updatedModuleCompletion = moduleCompletion[moduleId] || {
                          type: 'quiz',
                          dateCompleted: null,
                          lastSubmitDate: null,
                          currentQuestion: 1,
                          submitCount: 0,
                          answers: [],
                        };

                        onUpdate(moduleId, { ...updatedModuleCompletion });
                      }}
                    >
                      Start Quiz
                    </button>
                  </div>
                </div>
              }
            </>
          }
        </>
      }
    </div>
  );
}

export default QuizContent;
