import { useEffect, useState } from 'react';
import { getFirestore, doc, getDoc, updateDoc } from 'firebase/firestore';
import {
  getAuth,
  EmailAuthProvider,
  reauthenticateWithCredential,
  updatePassword,
  updateEmail,
} from 'firebase/auth';

import './Account.scss';
import Modal from '../../../shared/Modal';
import Loading from '../../../shared/Loading';

function Account({}) {
  const [loading, setLoading] = useState(true);
  const [modalTitle, setModalTitle] = useState('');
  const [modalText, setModalText] = useState('');
  const [user, setUser] = useState({
    userName: '',
    email: '',
  });
  const [changesMade, setChangesMade] = useState(false);
  const [savingChanges, setSavingChanges] = useState(false);
  const [passwordPromptOpen, setPasswordPromptOpen] = useState(false);
  const [showUpdatePassword, setShowUpdatePassword] = useState(false);
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [updatingPassword, setUpdatingPassword] = useState(false);

  useEffect(() => {
    fetchUser();
  }, []);

  const fetchUser = async () => {
    const auth = getAuth();
    const uid = auth.currentUser.uid;

    try {
      const db = getFirestore();
      const userRef = doc(db, 'users', uid);
      const userSnap = await getDoc(userRef);

      if (!userSnap.exists()) {
        setModalTitle('Error:');
        setModalText('There was an error retrieving you account data. If this problem persists, please contact us.');
        return;
      }

      const userData = userSnap.data();

      setUser({
        ...userData,
        id: userSnap.id,
      });
      setLoading(false);
    } catch (e) {
      console.log('error', e);
      setModalTitle('Error:');
      setModalText('There was an error retrieving you account data. If this problem persists, please contact us.');
    }
  };

  const closeUpdatePasswordModal = () => {
    setShowUpdatePassword(false);
    setCurrentPassword('');
    setNewPassword('');
    setConfirmPassword('');
  };

  const submitUpdatePassword = async () => {
    const current = currentPassword;
    const updated = newPassword;

    closeUpdatePasswordModal();
    setUpdatingPassword(true);

    try {
      const auth = getAuth();
      const currentUser = auth.currentUser;
      const credential = EmailAuthProvider.credential(currentUser.email, current);

      await reauthenticateWithCredential(currentUser, credential);
      await updatePassword(currentUser, updated);

      setUpdatingPassword(false);
      setModalTitle('Success!');
      setModalText('Your password has been updated.');
    } catch (e) {
      console.log('error', e);
      setUpdatingPassword(false);
      setModalTitle('Error:');
      setModalText('There was an error updating your password, please try again.');
    }
  };

  const saveChanges = async (currentPassword) => {
    const auth = getAuth();
    const currentUser = auth.currentUser;

    if (currentUser.email !== user.email && !currentPassword) {
      setPasswordPromptOpen(true);
      return;
    }

    setSavingChanges(true);

    try {
      const dataToUpdate = {
        userName: user.userName,
      };

      if (currentPassword) {
        const updatedEmail = user.email.trim().toLowerCase();
        const credential = EmailAuthProvider.credential(currentUser.email, currentPassword);

        await reauthenticateWithCredential(currentUser, credential);
        await updateEmail(currentUser, updatedEmail);

        dataToUpdate.email = updatedEmail;
      }

      const db = getFirestore();
      const userRef = doc(db, 'users', currentUser.uid);

      await updateDoc(userRef, dataToUpdate);

      setSavingChanges(false);
      setChangesMade(false);
      setModalTitle('Success!');
      setModalText('Your changes have been saved.');
    } catch (e) {
      console.log('error', e);
      setSavingChanges(false);
      setModalTitle('Error:');
      setModalText('There was an error updating your account, please try again.');
    }
  };

  const closePasswordPromptModal = () => {
    setCurrentPassword('');
    setPasswordPromptOpen(false);
  };

  return (
    <div className="Account">
      {!loading ? null : <Loading/>}

      {!user.id ? null :
        <div className="account-content-container">
          <div className="header-container">
            <h1>Account</h1>

            <button
              onClick={() => {
                if (updatingPassword) {
                  return;
                }

                setShowUpdatePassword(true);
              }}
              className="secondary update-password-button"
            >
              {updatingPassword ?
                <i className="fas fa-spinner fa-pulse"></i> :
                'Update Password'
              }
            </button>
          </div>

          <div>
            <div className="input-container">
              <label>Username:</label>
              <input
                type="text"
                value={user.userName}
                placeholder="Enter username"
                onChange={(e) => {
                  setUser({
                    ...user,
                    userName: e.target.value,
                  });
                  setChangesMade(true);
                }}
              />
            </div>

            <div className="input-container">
              <label>Email:</label>
              <input
                type="email"
                value={user.email}
                placeholder="me@example.com"
                onChange={(e) => {
                  setUser({
                    ...user,
                    email: e.target.value,
                  });
                  setChangesMade(true);
                }}
              />
            </div>
          </div>

          <button
            disabled={!changesMade}
            className="save-changes-button"
            onClick={() => {
              if (savingChanges) {
                return;
              }

              saveChanges();
            }}
          >
            {savingChanges ?
              <i className="fas fa-spinner fa-pulse"></i> :
              'Save Changes'
            }
          </button>
        </div>
      }

      <Modal
        open={showUpdatePassword}
        close={closeUpdatePasswordModal}
        title="Update Password"
        buttons={[
          <button
            key="modal-submit"
            className="small"
            onClick={submitUpdatePassword}
            disabled={!currentPassword || !newPassword || !confirmPassword || newPassword !== confirmPassword}
          >Submit</button>,
          <button
            key="modal-close"
            className="small secondary"
            onClick={closeUpdatePasswordModal}
          >Cancel</button>,
        ]}
      >
        <div>
          <div className="input-container">
            <label>Current Password:</label>
            <input
              type="password"
              value={currentPassword}
              placeholder="Enter current password"
              onChange={(e) => {
                setCurrentPassword(e.target.value);
              }}
            />
          </div>

          <div className="input-container">
            <label>New Password:</label>
            <input
              type="password"
              value={newPassword}
              placeholder="Enter new password"
              onChange={(e) => {
                setNewPassword(e.target.value);
              }}
            />
          </div>

          <div className="input-container">
            <label>New Password Confirmation:</label>
            <input
              type="password"
              value={confirmPassword}
              placeholder="Confirm new password"
              onChange={(e) => {
                setConfirmPassword(e.target.value);
              }}
            />
          </div>
        </div>
      </Modal>
      
      <Modal
        open={passwordPromptOpen}
        close={closePasswordPromptModal}
        title="Verify Password"
        buttons={[
          <button
            key="modal-submit"
            className="small"
            onClick={() => {
              saveChanges(currentPassword);
              closePasswordPromptModal();
            }}
            disabled={!currentPassword}
          >Submit</button>,
          <button
            key="modal-close"
            className="small secondary"
            onClick={closePasswordPromptModal}
          >Cancel</button>,
        ]}
      >
        <div>
          <p>Please verify your password in order to change your email.</p>
          <div className="input-container">
            <label>Password:</label>
            <input
              type="password"
              value={currentPassword}
              placeholder="Enter password"
              onChange={(e) => {
                setCurrentPassword(e.target.value);
              }}
            />
          </div>
        </div>
      </Modal>

      <Modal
        open={!!modalText}
        close={() => {
          setModalTitle('');
          setModalText('');
        }}
        title={modalTitle}
        buttons={[
          <button key="modal-close" className="small" onClick={() => {
            setModalTitle('');
            setModalText('');
          }}>Close</button>,
        ]}
      >
        <div>
          <div className="modal-text">{modalText}</div>
        </div>
      </Modal>
    </div>
  );
}

export default Account;
