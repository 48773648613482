export const authCodesMap = {
  'auth/email-already-in-use': 'The provided email is already in use.',
  'auth/internal-error': 'An error occurred, please try again.',
  'auth/invalid-email': 'The provided email address is invalid.',
  'auth/wrong-password': 'The provided password is incorrect. Please try again.',
  'auth/account-exists-with-different-credential': 'An account with this email address already exists.',
  'auth/too-many-requests': 'You have had too many unsuccessful attempts and your account has been temporarily locked to prevent hackers. Please try again later.',
  'auth/unverified-email': 'Verify you email to continue.',
  'auth/user-not-found': 'A user with the provided credentials was not found. Please try again.',
  'auth/user-disabled': 'Your account has been disabled.',
  'auth/weak-password': 'The provided password is too weak. Please provide a stronger password',
  'auth/missing-email': 'Please enter your email address.',
};
