import { useEffect, useState } from 'react';
import { getAuth } from 'firebase/auth';
import { getFirestore, doc, getDoc } from 'firebase/firestore';
import { useDispatch } from 'react-redux';

import './Dashboard.scss';
import Admin from './Admin';
import Associate from './Associate';
import Modal from '../shared/Modal';
import { setOrganization } from '../../store/actions';

function Dashboard({}) {
  const dispatch = useDispatch();
  const [role, setRole] = useState('');
  const [showSignOutModal, setShowSignOutModal] = useState(false);
  const [signOutModalMessage, setSignOutModalMessage] = useState('');

  useEffect(() => {
    initializeDashboard();
  }, []);

  const initializeDashboard = async () => {
    try {
      const auth = getAuth();
      const result = await auth.currentUser.getIdTokenResult();

      if (
        result &&
        result.claims &&
        result.claims.roles &&
        result.claims.partner &&
        Array.isArray(result.claims.roles) &&
        result.claims.roles[0] &&
        (result.claims.roles[0] === 'partner-admin' || result.claims.roles[0] === 'partner-associate')
      ) {
        const db = getFirestore();
        const partnerRef = doc(db, 'partners', result.claims.partner);
        const partnerSnap = await getDoc(partnerRef);
        const partner = partnerSnap.data();

        setRole(result.claims.roles[0]);

        if (partner) {
          dispatch(setOrganization({
            id: partnerSnap.id,
            ...partner,
          }));
        }
      } else {
        setSignOutModalMessage('You do not have access to this resource. If you need assistance, please contact us at hello@pupford.com.');
        setShowSignOutModal(true);
      }
    } catch (e) {
      console.log('error', e);
      setSignOutModalMessage('There was an error retrieving your user profile. If you need assistance, please contact us at hello@pupford.com.');
      setShowSignOutModal(true);
    }
  };

  const closeSignOutModal = async () => {
    setShowSignOutModal(false);
    setSignOutModalMessage('');

    const auth = getAuth();
    auth.signOut()
  };

  return (
    <div className="Dashboard">
      {!role ? 
        <div>
          Loading...
        </div> : (role === 'partner-admin' ? <Admin/> : <Associate/>)
      }

      <Modal
        open={showSignOutModal}
        close={closeSignOutModal}
        title="Notice"
        buttons={[
          <button key="modal-close" className="small" onClick={closeSignOutModal}>Close</button>,
        ]}
      >
        <div>
          <div className="modal-text">{signOutModalMessage}</div>
        </div>
      </Modal>
    </div>
  );
}

export default Dashboard;
