import {
  SET_ORGANIZATION,
  UPDATE_ORGANIZATION,
} from './types';

export const setOrganization = (organization) => {
  return {
    type: SET_ORGANIZATION,
    payload: organization,
  };
};

export const updateOrganization = (organizationData) => {
  return {
    type: UPDATE_ORGANIZATION,
    payload: organizationData,
  };
};
