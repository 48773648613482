import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import './Courses.scss';
import Loading from '../../../shared/Loading';

function Courses({}) {
  const courses = useSelector(state => state.courses.courses);

  return (
    <div className="Courses">
      <div className="courses-content">
        <div className="associate-max-width">
          {!courses.length ?
            <div className="courses-loading-container">
              <Loading position="absolute"/>
            </div> :
            <div>
              {courses.map(c => {
                return (
                  <div className="course-outer-container" key={c.id}>
                    <Link to={`/course/${c.webPath}`}>
                      <div className="course-container">
                        <div className="course-image-container">
                          <img src={c.backgroundImage} alt={c.name}/>
                        </div>
                        <div className="course-details-container">
                          <p className="course-title">{c.name}</p>
                          <p className="course-description">{c.description}</p>
                        </div>
                      </div>
                    </Link>
                  </div>
                );
              })}
            </div>
          }
        </div>
      </div>
    </div>
  );
}

export default Courses;
