import {
  SET_COURSES,
} from '../actions/types';

const defaultState = {
  initialized: false,
  courses: [],
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case SET_COURSES:
      return {
        ...state,
        initialized: true,
        courses: action.payload,
      };
    default:
      return state;
  }
};
