import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAuth } from 'firebase/auth';
import { Link, Route, Switch } from 'react-router-dom';
import { getFirestore, collection, query, where, getDocs } from 'firebase/firestore';

import './Admin.scss';
import PupfordLogo from '../../../images/pupford-logo.png';
import Header from '../../layout/Header';
import Sidebar from '../../layout/Sidebar';
import Overview from './Overview';
import Team from './Team';
import Account from './Account';
import Course from '../Associate/Course';
import Courses from './Courses';
import { setAssociateCompletion, setCourses } from '../../../store/actions';

function Admin({}) {
  const dispatch = useDispatch();
  const user = useSelector(state => state.user);
  const organizationLogo = useSelector(state => state.organization.logoUrl);
  const coursesInitialized = useSelector(state => state.courses.initialized);
  const [navClosed, setNavClosed] = useState(true);

  useEffect(() => {
    if (!coursesInitialized && user.email) {
      fetchCourses(user.uid);
    }
  }, [coursesInitialized, user.email]);

  const fetchCourses = async (userId) => {
    try {
      const db = getFirestore();

      const q = query(collection(db, 'associate-training-courses'), where('enabled', '==', true));
      const coursesSnapshot = await getDocs(q);
      const completionQuery = query(collection(db, 'associate-training-completion'), where('userId', '==', userId));
      const completionSnapshot = await getDocs(completionQuery);

      const courses = coursesSnapshot.docs.map(doc => {
        return {
          ...doc.data(),
          id: doc.id,
        };
      });

      const courseCompletionMap = {};

      completionSnapshot.docs.forEach(doc => {
        const data = doc.data();

        courseCompletionMap[data.courseId] = { ...data, id: doc.id };
      });

      dispatch(setCourses(courses));
      dispatch(setAssociateCompletion({
        courses: courseCompletionMap,
      }));
    } catch (e) {
      console.log('error', e);
    }
  };

  return (
    <div className="Admin">
      <Header
        profileImageUrl={(user.profileImage && user.profileImage.url) || ''}
        userName={`${user.userName ? user.userName : ''}`}
        openNav={() => setNavClosed(false)}
      />

      <div className="main-container">
        <div className="nav-container">
          <div className={`nav-inner${navClosed ? ' closed' : ''}`}>
            <div className="logo-container">
              <i onClick={() => setNavClosed(true)} className="fas fa-times close"></i>
              <Link to="/">
                <img
                  className="logo"
                  src={organizationLogo || PupfordLogo}
                  alt=""
                />
              </Link>
            </div>

            <Sidebar
              logOut={() => {
                const auth = getAuth();
                auth.signOut();
              }}
              closeSidebar={() => setNavClosed(true)}
              links={[
                // {
                //   route: '/',
                //   name: 'Dashboard',
                //   iconClassName: 'fas fa-chart-area',
                // },
                {
                  route: '/team',
                  name: 'Team',
                  iconClassName: 'fas fa-users',
                },
                {
                  route: '/courses',
                  name: 'Courses',
                  iconClassName: 'fas fa-user',
                },
                {
                  route: '/account',
                  name: 'Account',
                  iconClassName: 'fas fa-user',
                },
              ]}
            />
          </div>
        </div>

        <div className="content-container">
          <div className="content-inner">
            <Switch>
              <Route path="/team" component={Team} />
              <Route path="/account" component={Account} />
              <Route path="/courses" component={Courses} />
              <Route path="/course/:path" component={Course} />
              {/* <Route component={Overview} /> */}
              <Route component={Team} />
            </Switch>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Admin;
