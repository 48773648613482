import { combineReducers } from 'redux';

import UserReducer from './userReducer';
import OrganizationReducer from './organizationReducer';
import TeamReducer from './teamReducer';
import CourseReducer from './courseReducer';
import AssociateCompletionReducer from './associateCompletionReducer';

export default combineReducers({
  user: UserReducer,
  organization: OrganizationReducer,
  team: TeamReducer,
  courses: CourseReducer,
  associateCompletion: AssociateCompletionReducer,
});
