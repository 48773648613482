import React from 'react';
import { Link, useLocation } from 'react-router-dom';

import './Sidebar.scss';

function Sidebar({
  closeSidebar,
  logOut,
  links,
}) {
  let location = useLocation();

  return (
    <div className="Sidebar">
      {links.map(link => {
        return (
          <Link to={link.route} key={link.route}>
            <div
              onClick={closeSidebar}
              className={`nav-item${location.pathname === link.route ? ' active' : ''}`}
            >
              <i className={link.iconClassName}></i>
              {link.name}
            </div>
          </Link>
        );
      })}
      <a>
        <div className="nav-item sign-out" onClick={logOut}>
          <i className="fas fa-sign-out-alt"></i>
          Sign Out
        </div>
      </a>
    </div>
  );
}

export default Sidebar;
