import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

import './Header.scss';
import PupfordLogo from '../../../images/pupford-logo.png';

function Header({ openNav, userName, profileImageUrl, hideNav }) {
  const organizationLogo = useSelector(state => state.organization.logoUrl);

  return (
    <div className="Header">
      <div className="header-left-container">
        {hideNav ? null : <i onClick={openNav} className="fas fa-bars"></i>}
        <Link to="/">
          <img
            src={organizationLogo || PupfordLogo}
            alt=""
          />
          {!organizationLogo ? null :
            <div className="powered-by-container">
              <p>Powered By</p>
              <img
                src={PupfordLogo}
                alt=""
              />
            </div>
          }
        </Link>
      </div>

      <div className="user-info-container">
        <p className="user-name">Welcome{userName ? `, ${userName}` : ''}</p>

        <Link to="/account">
          <div className="profile-image-container">
            {!profileImageUrl ? <i className="fas fa-paw"></i> :
              <img src={profileImageUrl} alt="User"/>
            }
          </div>
        </Link>
      </div>
    </div>
  );
}

export default Header;
