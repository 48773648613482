import {
  SET_ASSOCIATE_COMPLETION,
  ADD_COURSE_TO_ASSOCIATE_COMPLETION,
  UPDATE_ASSOCIATE_COMPLETION_COURSE,
} from '../actions/types';

const defaultState = {
  initialized: false,
  courses: {},
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case SET_ASSOCIATE_COMPLETION:
      return {
        ...state,
        initialized: true,
        ...action.payload,
      };
    case ADD_COURSE_TO_ASSOCIATE_COMPLETION:
      return {
        ...state,
        courses: {
          ...state.courses,
          [action.payload.courseId]: action.payload,
        },
      };
    case UPDATE_ASSOCIATE_COMPLETION_COURSE:
      return {
        ...state,
        courses: {
          ...state.courses,
          [action.payload.courseId]: action.payload,
        },
      };
    default:
      return state;
  }
};
